<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc('tenants.title', 2) }}
        </label>
        <v-select
          data-cy="automatest-v-select-tenants"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="tenantFilter"
          :options="tenantOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:tenantFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc('country.title', 2) }}
        </label>
        <v-select
          data-cy="automatest-v-select-country"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="countryFilter"
          :options="countryOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:countryFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc('region.title', 2) }}
        </label>
        <v-select
          data-cy="automatest-v-select-region"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="regionFilter"
          :options="regionOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:regionFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc('municipality.title', 2) }}
        </label>
        <v-select
          data-cy="automatest-v-select-municipality"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="municipalityFilter"
          :options="municipalityOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:municipalityFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t('camera.online') }}</label>
        <v-select
          data-cy="automatest-v-select-camera.online"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="onlineFilter"
          class="w-100"
          append-to-body
          :calculate-position="withPopper"
          :reduce="val => val.value"
          :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
          @input="(val) => $emit('update:onlineFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t('router.notify_cad_c5_cdmx') }}</label>
        <v-select
          data-cy="automatest-v-select-notify_cad_c5_cdmx"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="sendC5CdmxFilter"
          class="w-100"
          :reduce="val => val.value"
          append-to-body
          :calculate-position="withPopper"
          :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
          @input="(val) => $emit('update:sendC5CdmxFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t('router.notify_cad_c5_jalisco') }}</label>
        <v-select
        data-cy="automatest-v-select-notify_cad_c5_jalisco"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="sendC5JalFilter"
          class="w-100"
          :reduce="val => val.value"
          append-to-body
          :calculate-position="withPopper"
          :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
          @input="(val) => $emit('update:sendC5JalFilter', val)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import popperHelper from "@/libs/popper-helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    countryOptions: {
      type: Array,
      default: null,
    },
    countryFilter: {
      type: Array,
      default: null,
    },
    regionOptions: {
      type: Array,
      default: null,
    },
    regionFilter: {
      type: Array,
      default: null,
    },
    municipalityOptions: {
      type: Array,
      default: null,
    },
    municipalityFilter: {
      type: Array,
      default: null,
    },
    onlineFilter: {
      type: Boolean,
      default: null,
    },
    sendC5CdmxFilter: {
      type: Boolean,
      default: null,
    },
    sendC5JalFilter: {
      type: Boolean,
      default: null,
    }
  },
  setup() {
    return {
      withPopper: popperHelper.withPopper,
    }
  }
};
</script>
