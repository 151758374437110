<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :tenant-options="tenantOptions"
      :region-options="regionOptions"
      :country-options="countryOptions"
      :municipality-options="municipalityOptions"
    />
    <b-row>
      <b-col cols="6">
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>
      <b-col cols="6" class="pr-3 my-2 text-right">
        <button-filters
          @show-modal="$root.$emit('bv::show::modal', $event)"
          :filters-count="filtersCount"
          @clear-search-filters="clearSearchFilters"
        />
      </b-col>
    </b-row>

    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast"/>

    <!-- Filters -->
    <filter-modal
      @action="buildFilters()"
      @clear="clearSearchFilters()"
      >
      <template v-slot:main-filters>
        <filters
          :tenant-filter.sync="tenantFilter"
          :tenant-options="tenantOptions"
          :online-filter.sync="onlineFilter"
          :send-c5-cdmx-filter.sync="sendC5CdmxFilter"
          :send-c5-jal-filter.sync="sendC5JalFilter"
          :municipality-options="municipalityOptions"
          :municipality-filter.sync="municipalityFilter"
          :country-options="countryOptions"
          :country-filter.sync="countryFilter"
          :region-options="regionOptions"
          :region-filter.sync="regionFilter"
        />
      </template>
    </filter-modal>



    <!-- Table Container Card -->
    <b-card-group deck class="mx-2">
    <b-card class="m-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
            data-cy="automatest-v-select-pagination-control"
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                data-cy="search"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                @click="openAdd()"
                v-if="$can('create', 'Unit')">
                <span class="text-nowrap text-capitalize">
                  {{ $t("actions.add") + " " + $tc("unit.title", 1) }}
                </span>
              </b-button>
              <b-button
                v-b-modal.import_file
                variant="info"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
                v-b-tooltip.hover
                v-if="$can('create', 'Unit')"
                :title="$t('messages.import.title')"
              >
                <feather-icon icon="UploadCloudIcon" size="15"/>
              </b-button>
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
                v-b-tooltip.hover
                :title="$t('messages.export.title')"
              >
                <feather-icon icon="DownloadCloudIcon" size="15"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Modal Import -->
      <div v-if="$can('create', 'Unit')">
        <b-modal
          id="import_file"
          :title="`${$t('messages.import.title')}`"
          no-close-on-esc
          no-close-on-backdrop
          ref="modalImport"
        >
          <vue-dropzone
            id="dropzone_file"
            ref="dropzoneFile"
            :options="dropzoneOptions"
            class="dropzone-file"
            @vdropzone-file-added="addFile"
            @vdropzone-removed-file="removeFile"
          />
          <div
            v-if="importMessage.length > 0"
            class="mt-2 scroll">
            <span
              v-for="(message, index) in importMessage"
              :key="index"
            >
              <p
                class="text-danger mb-0"
                v-for="(error, index) in message"
                :key="index">
                {{ error }}
              </p>
            </span>
          </div>
          <template #modal-footer>
            <a :href="import_example_url" download>{{ $t('messages.import.example_file') }}</a>
            <b-button
              variant="secondary"
              @click="$bvModal.hide('import_file')"
              :disabled="buttonExport">
              {{ $t('actions.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              @click="importExcel"
              :disabled="buttonExport">
              {{ $t('actions.accept') }}
            </b-button>
          </template>
        </b-modal>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: User -->
        <template #cell(description)="data">
          <b-media vertical-align="center">
            <template #aside>
              <div :style="`background: ${data.item.router.active ? 'green': 'red'};
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 1rem;
                        text-align: center;`">
              </div>
            </template>
            <strong>{{ data.item.description }}</strong>
            <br />
            <small>{{ data.item.router.tenant.name }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            :id="`actions-${data.item.id}`"
            tabindex="0"
            variant="white"
            class="px-1 btn-y"
          >
            <feather-icon
                data-cy="actions"
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-button>
          <b-popover
              :target="`actions-${data.item.id}`"
              triggers="focus"
              placement="bottom"
              custom-class="popover-p-0"
              variant="white"
          >
            <b-list-group class="m-0">

              <b-list-group-item
                @click="openEdit(data.item)"
                v-if="$can('update', 'Unit')"
                class="border-0"
                button
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{
                  $t("actions.edit")
                }}</span>
              </b-list-group-item>
              <b-list-group-item
                @click="confirmDelete(data.item.id)"
                v-if="$can('delete', 'Unit')"
                class="border-0"
                button
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{
                  $t("actions.delete")
                }}</span>
              </b-list-group-item>
              <b-list-group-item
                v-if="$can('update', 'Unit')"
                class="border-0"
                :to="{ name: 'plane', params: { id: data.item.id } }"
              >
                <feather-icon icon="MapIcon" />
                <span class="align-middle ml-50">
                  planos
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              data-cy="pagination"
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent
  from '@core/components/toastification/ToastificationLoadingContent.vue'
import store from "@/store";
import Filters from "./Filters.vue";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Breadcrumb from "@/components/Breadcrumb.vue";
import FilterModal from "@/components/Filters/FilterModal.vue";
import ButtonFilters from "@/components/Filters/ButtonFilters.vue";

export default {
  components: {
    Breadcrumb,
    Sidenav,
    ButtonFilters,
    FilterModal,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
    vueDropzone: vue2Dropzone,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    // start export
    const action = ref("list");
    const exportExcel = () => {
      action.value == "export";
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("unit/export", {
          q: searchQuery.value,
          max: perPage.value,
          'page[n]': currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          action: "export",
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };

    // end export

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.facilities.all_facilities");
    // end breadcrumbs
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const modalImport = ref();
    const loadingToast = ref();

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      itemEdit.value.hasRouter = 1
      isAdd.value = false;
    };

    const toast = useToast();

    const {t} = useI18nUtils()
    const buttonExport = ref(false)
    const trans = {
      'messages.import.upload': t('messages.import.upload'),
      'messages.import.uploading_file': t('messages.import.uploading_file'),
      'messages.import.upload_succesfully': t('messages.import.upload_succesfully'),
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    }

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      onlineFilter,
      countryFilter,
      countryOptions,
      regionFilter,
      regionOptions,
      municipalityOptions,
      municipalityFilter,
      sendC5CdmxFilter,
      sendC5JalFilter,
      filtersCount,
      clearSearchFilters,
      buildFilters,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: trans["messages.delete.title"],
        text: trans["messages.delete.body"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: trans["actions.delete"],
        cancelButtonText: trans["actions.cancel"],
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("unit/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      paramName: "file",
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: t("actions.delete"),
      acceptedFiles: ".xlsx,.xls",
      maxFilesize: 10,
      dictDefaultMessage: t('messages.import.select_file') + ' (.xlsx .xls)',
    });

    const dropzoneFile = ref(null);
    const importMessage = ref([]);

    const addFile = (file) => {
      importMessage.value = [];
      dropzoneFile.value = file;
      if (file.size > 10000000) {
        dropzoneFile.value = null
      }
    };

    const removeFile = () => {
      dropzoneFile.value = null;
    };

    const importExcel = () => {

      if (dropzoneFile.value == null) return;

      loadingToast.value.show(trans['messages.import.upload'], trans['messages.import.uploading_file'], 'secondary')
      buttonExport.value = true

      let formData = new FormData();
      formData.append('file', dropzoneFile.value);
      formData.append('action', 'import');

      store.dispatch('unit/add', formData)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            modalImport.value.hide()
          }
        })
        .catch(response => {
          importMessage.value = response.response.data.data
          loadingToast.value.close()
          toast({
            component: ToastificationContent,
            props: {
              title: `${response.response.data.message}`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          buttonExport.value = false
          loadingToast.value.close()
        })
    }

    return {
      action,
      exportExcel,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      update,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tenantOptions,
      tenantFilter,
      onlineFilter,
      countryFilter,
      countryOptions,
      regionOptions,
      regionFilter,
      municipalityOptions,
      municipalityFilter,
      sendC5CdmxFilter,
      sendC5JalFilter,
      buttonExport,
      importExcel,
      dropzoneOptions,
      addFile,
      removeFile,
      importMessage,
      loadingToast,
      modalImport,
      filtersCount,
      clearSearchFilters,
      buildFilters,
      import_example_url: `${process.env.VUE_APP_BASE_URL}/import_examples/import_facilities.xlsx`
    };
  },
};
</script>



<style lang="scss">
.router {
  max-width: 500px;
}

.dropzone-file {
  padding: 0px;
  margin: auto;
  border: none;
  max-width: 250px;
  min-height: 200px;
  display: flex;
  box-shadow: inset 0 0 0 3px #dfdfdf;
}

.dropzone {
  .dz-preview {
    margin: 0px;
    width: 100%;
  }

  .dz-message {
    text-align: center;
    margin: auto;
  }
}

.scroll {
  width: 100%;
  height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #1b3d5e;
}
</style>
