import {ref, computed, watch} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import useFilterCounter from '@/components/Filters/useFiltersCount'
import {debounce} from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  const tableColumns = [
    {label: 'actions.name', key: 'actions'},
    // { label: 'tenants.title', key: 'router.tenant.name', sortable: false },
    {label: 'unit.description', key: 'description', sortable: true},
    {
      label: 'router.imei',
      key: 'routers.imei',
      sortable: false,
      tdClass: 'text-truncate router',
      thStyle: 'max-width: 150px;'
    },
    {label: 'unit.camera_count', key: 'cameras.length', sortable: false},
    {
      label: 'country.title',
      key: 'country.name',
      sortable: false
    },
    {label: 'region.title', key: 'regions.name', sortable: true},
    {
      label: 'municipality.title',
      key: 'municipalities.name',
      sortable: true
    },
    {label: 'router.ip_vpn', key: 'router.ip_vpn', sortable: false},
    {label: 'router.latitude', key: 'routers.lat', sortable: false},
    {label: 'router.longitude', key: 'routers.lng', sortable: false},
    {label: 'router.notify_cad_c5_cdmx', key: 'routers.notify_c5_cdmx', sortable: true},
    {label: 'router.notify_cad_c5_jalisco', key: 'routers.notify_c5_jal', sortable: true},
    {label: 'unit.address_1', key: 'address_1', sortable: false},
    {label: 'unit.address_2', key: 'address_2', sortable: false},
    {label: 'unit.exterior_number', key: 'exterior_number', sortable: false},
    {label: 'unit.suburb', key: 'suburb', sortable: true},
    {label: 'unit.number', key: 'number', sortable: false},
    {label: 'unit.postal_code', key: 'postal_code', sortable: true},
    {label: 'unit.route', key: 'route', sortable: false},
    {label: 'unit.plate_number', key: 'plate_number', sortable: true},
    {label: 'unit.vin', key: 'vin', sortable: true},
    {label: 'unit.driver', key: 'driver', sortable: true},
    {label: 'unit.provider', key: 'provider', sortable: true},
    {label: 'unit.company', key: 'owner', sortable: true},
    {label: 'unit.vehicle', key: 'vehicle', sortable: true},
    {
      label: 'unit.allow_virtual_button_alarm',
      key: 'allow_virtual_button_alarm',
      sortable: true
    },
    {label: 'unit.active_digital_output', key: 'active_digital_output', sortable: true},
    {
      label: 'unit.allow_alarms',
      key: 'router.allow_alarms',
      sortable: true,
    },

  ]

  if (ability.can('update', 'Unit') == true || ability.can('delete', 'Unit') == true) {
    tableColumns.splice(0, 0, {
      label: 'actions.name',
      key: 'actions'
    })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const onlineFilter = ref(null);
  const sendC5CdmxFilter = ref(null);
  const sendC5JalFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  store.dispatch('tenant/getAll')
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  store.dispatch('country/getAll')
  const countryOptions = computed(() => store.getters['country/getSelectedItems'])
  const countryFilter = ref(null)

  store.dispatch('region/getAll')
  const regionOptions = computed(() => store.getters['region/getSelectedItems'])
  const regionFilter = ref(null)

  store.dispatch('municipality/getAll')
  const municipalityOptions = computed(() => store.getters['municipality/getSelectedItems'])
  const municipalityFilter = ref(null)

  const fetchList = (ctx, callback) => {
    store.dispatch('unit/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      tenant: tenantFilter.value,
      country: countryFilter.value,
      region: regionFilter.value,
      municipality: municipalityFilter.value,
      online: onlineFilter.value,
      send_c5_cdmx: sendC5CdmxFilter.value,
      send_c5_jal: sendC5JalFilter.value,
      with: ['router', 'router.tenant', 'country', 'region', 'municipality', 'cameras']
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
    ], () => {
      refetchData()
    })

  watch([searchQuery], debounce(() => {
    refetchData()
  }, 500));

  // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      tenants: tenantFilter.value,
      countries: countryFilter.value,
      regions: regionFilter.value,
      municipalities: municipalityFilter.value,
      online: onlineFilter.value,
      send_c5_cdmx: sendC5CdmxFilter.value,
      send_c5_jal: sendC5JalFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    tenantFilter.value = null;
    countryFilter.value = null;
    regionFilter.value = null;
    municipalityFilter.value = null;
    onlineFilter.value = null;
    sendC5CdmxFilter.value = null;
    sendC5JalFilter.value = null;
    filters.value = {};
    buildFilters();
  }
  // ============================================

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    tenantOptions,
    tenantFilter,
    onlineFilter,
    regionOptions,
    regionFilter,
    countryOptions,
    countryFilter,
    municipalityOptions,
    municipalityFilter,
    sendC5CdmxFilter,
    sendC5JalFilter,
    refetchData,
    filtersCount,
    clearSearchFilters,
    buildFilters,
  }
}
